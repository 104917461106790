<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <md-card v-show="showLogin">
            <md-card-header class="md-card-header-icon md-card-header-green">
              <div class="card-icon">
                <md-icon>contacts</md-icon>
              </div>
              <h4 class="title text-center">Login</h4>
            </md-card-header>

            <md-card-content>
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Email</label>
                  <md-icon>email</md-icon>
                  <md-input v-model="form.email" type="email"> </md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>

              <ValidationProvider
                name="senha"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Senha</label>
                  <md-icon>lock_outline</md-icon>
                  <md-input v-model="form.senha" type="password"> </md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </md-card-content>

            <md-card-actions>
              <md-button type="submit" class="md-success small-alert-modal"
                >Login
              </md-button>
              <md-button class="md-raised" @click="clickEsqueciSenha()"
                >Esqueceu a senha ?</md-button
              >
            </md-card-actions>
          </md-card>
        </form>
      </ValidationObserver>

      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(esqueciSenha)">
          <md-card v-show="showEsqueciSenha">
            <md-card-header class="md-card-header-icon md-card-header-green">
              <div class="card-icon">
                <md-icon>contacts</md-icon>
              </div>
              <h4 class="title text-center">Esqueceu a senha ?</h4>
              <br />
              <small
                >Informe seu email e será enviado um link para recuperar a
                senha</small
              >
            </md-card-header>

            <md-card-content>
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Email Adress</label>
                  <md-icon>email</md-icon>
                  <md-input v-model="form2.email" type="email"> </md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </md-card-content>

            <md-card-actions>
              <md-button
                type="button"
                class="md-raised"
                @click="clickEsqueciSenha()"
              >
                VOLTAR
              </md-button>
              <md-button type="submit" class="md-success small-alert-modal"
                >Recuperar Senha
              </md-button>
            </md-card-actions>
          </md-card>

          <template>
            <div class="small-alert-modal"></div>
            <modal
              class="small-alert-modal"
              v-if="smallAlertModal"
              @close="smallAlertModalHide"
            >
              <template slot="header"> </template>

              <template slot="body" class="md-danger">
                <p class="modal-text">{{ mensagem }}</p>
              </template>
              <template slot="footer">
                <md-button @click="smallAlertModal = false" class="md-danger"
                  >OK</md-button
                >
              </template>
            </modal>
          </template>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { SlideYDownTransition } from "vue2-transitions";
import { extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { Modal } from "@/components";
import Swal from "sweetalert2";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import Vue from "vue";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export default {
  components: {
    SlideYDownTransition,
    Modal
  },
  created() {
    /*this.$validator.extend("upCase", {
      getMessage: () => "One uppercase character",
      validate: value => value.match(/[A-Z]/g) !== null
    });
    this.$validator.extend("number", {
      getMessage: () => "One number",
      validate: value => value.match(/[0-9]/g) !== null
    });
    */
  },
  data() {
    return {
      form: {
        email: "",
        senha: ""
      },
      form2: {
        email: ""
      },
      smallAlertModal: false,
      mensagem: "",
      showLogin: true,
      showEsqueciSenha: false
    };
  },
  computed: {
    ...mapGetters("auth", ["hasToken"])
    //...mapActions("auth", ["ActionSignOut"])
  },
  methods: {
    smallAlertModalHide: function() {
      this.classicModal = false;
    },
    ...mapActions("auth", [
      "ActionDoLogin",
      "ActionLoadSession",
      "ActionEsqueciSenha"
    ]),
    async submit() {
      try {
        let response = await this.ActionDoLogin(this.form);
        let response2 = await this.ActionLoadSession();
        // .then(this.$router.push({ name: "Home" }))
        // .catch(this.$router.push({ name: "login" }));
        this.$router.push({ name: "Home" });
      } catch (err) {
        //this.mensagem = "Senha e/ou email não conferem.";
        if (
          err === 400 ||
          err === 500 ||
          err === 404 ||
          err.status === 400 ||
          err.status === 500 ||
          err.status === 404
        ) {
          this.mensagem = "Aplicação temporariamente indisponível.";
        } else if (err.status === 401 || err.data === "Unauthorized") {
          this.mensagem = "Senha e/ou email não conferem.";
        } else {
          if (err.body) {
            this.mensagem = err.body.mensagem;
          } else if (err.status === 0) {
            this.mensagem =
              "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
          } else {
            this.mensagem = "Senha e/ou email não conferem.";
          }
        }
        this.smallAlertModal = true;
        //alert(this.mensagem);
      }
    },
    clickEsqueciSenha() {
      this.showLogin = this.showEsqueciSenha;
      this.showEsqueciSenha = !this.showLogin;
    },
    async esqueciSenha() {
      try {
        await this.ActionEsqueciSenha(this.form2).then(reps => {
          this.mensagem = reps.body.mensagem;
          if (reps.status === 200) {
            Swal.fire({
              //title: "Sucesso",
              icon: "success",
              text: this.mensagem,
              showCancelButton: false,
              customClass: {
                confirmButton: "md-button md-success btn-fill"
              },
              buttonsStyling: false,
              confirmButtonText: "Ok"
            }).then(result => {
              if (result.value) {
                this.clickEsqueciSenha();
                this.resetForm();
                window._Vue.$router.push({ name: "login" }).catch(err => {});
              }
            });
          }
        });
      } catch (err) {
        this.handleError(err);
      }
    },
    resetForm() {
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 400) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  color: red;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.md-field label {
  left: 25px;
}

#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}
</style>
