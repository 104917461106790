<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-100"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SlideYDownTransition } from "vue2-transitions";
import { extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { Modal } from "@/components";
import Swal from "sweetalert2";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import Vue from "vue";
export default {
  beforeMount() {
    this.logout();
  },
  mounted() {
    window._Vue.$router.push({ name: "login" }).catch(err => {});
  },
  methods: {
    ...mapActions("auth", ["ActionSignOut"]),
    async logout() {
      this.ActionSignOut();
    }
  }
};
</script>
<style lang="scss">
.text-right {
  display: flex;
}
</style>
